import type { GetLegacyDesignTypesResponseZod } from "@nlc/shared-types";
import type { DefaultError } from "@tanstack/react-query";
import type { z } from "zod";

import { useQuery } from "@tanstack/react-query";
import { Controller } from "react-hook-form";

import {
  ComboBox,
  ComboBoxButton,
  ComboBoxGroup,
  ComboBoxInput,
  ComboBoxListBox,
  ComboBoxListItem,
  ComboBoxPopover,
  ComboBoxSection,
} from "@/components/combobox";
import { FieldError, Label } from "@/components/field";
import { Text } from "@/components/text";
import { legacyDesignTypesKeys } from "@/hooks/queryKeys";
import { useGetCurrentProjectId } from "@/hooks/useGetCurrentProjectId";
import apiClient from "@/lib/api/client";

import { useCreateGraphicRequestFormContext } from "../useCreateGraphicRequestForm";

type GetLegacyDesignTypesResponse = z.infer<
  typeof GetLegacyDesignTypesResponseZod
>;

function useGetLegacyDesignTypes(projectId?: number) {
  return useQuery<
    GetLegacyDesignTypesResponse,
    DefaultError,
    GetLegacyDesignTypesResponse["designTypes"][number]["subcategories"]
  >({
    enabled: !!projectId,
    queryFn: async () =>
      await apiClient
        .url(`/design-types/picker/legacy/${projectId}?category=graphic`)
        .get()
        .json(),
    queryKey: legacyDesignTypesKeys.legacyDesignTypesByProjectId(projectId),
    select: (data) => data.designTypes[0]?.subcategories ?? [],
  });
}

export function SelectDesignType() {
  const projectId = useGetCurrentProjectId();
  const { data } = useGetLegacyDesignTypes(projectId);
  const { control } = useCreateGraphicRequestFormContext();

  if (data) {
    return (
      <Controller
        control={control}
        name="designType"
        render={({
          field: { name, onBlur, onChange, ref, value },
          fieldState: { error, invalid },
        }) => (
          <ComboBox
            allowsEmptyCollection
            isInvalid={invalid}
            menuTrigger="input"
            name={name}
            onBlur={onBlur}
            onSelectionChange={onChange}
            selectedKey={value}
          >
            <Label requiredHint>Design Type</Label>
            <ComboBoxGroup>
              <ComboBoxInput placeholder="Select Design Type" ref={ref} />
              <ComboBoxButton />
            </ComboBoxGroup>
            <FieldError>{error?.message}</FieldError>

            <ComboBoxPopover>
              <ComboBoxListBox
                className="max-h-[291px]"
                renderEmptyState={() => (
                  <Text className="p-2">No results found</Text>
                )}
              >
                {data.map((subcategory) => (
                  <ComboBoxSection
                    key={subcategory.name}
                    title={subcategory.name}
                  >
                    {subcategory.types.map((type) => (
                      <ComboBoxListItem id={type.id} key={type.name}>
                        {type.name}
                      </ComboBoxListItem>
                    ))}
                  </ComboBoxSection>
                ))}
              </ComboBoxListBox>
            </ComboBoxPopover>
          </ComboBox>
        )}
      />
    );
  }

  return (
    <ComboBox allowsEmptyCollection>
      <Label requiredHint>Design Type</Label>
      <ComboBoxGroup>
        <ComboBoxInput placeholder="Loading..." />
        <ComboBoxButton />
      </ComboBoxGroup>
    </ComboBox>
  );
}
