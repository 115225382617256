import { Search } from "lucide-react";
import { useEffect, useRef, useState } from "react";

type SearchButtonProps = {
  maxWidth?: string;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: string;
};

export function SearchButton({
  onSearch,
  placeholder = "Search a request type...",
  value,
}: SearchButtonProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isExpanded && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isExpanded]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent): void => {
      if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="flex justify-end">
      <div
        className={`flex origin-right items-center rounded-lg border border-border-primary transition-all duration-300 ease-in-out
          ${isExpanded ? "h-[36px] w-[320px] bg-bg-primary" : "h-[36px] w-[39px] cursor-pointer bg-bg-primary hover:bg-bg-tertiary"}`}
      >
        {isExpanded ? (
          <div className="flex w-full items-center px-4 py-2">
            <Search className="mr-2 size-2xl text-fg-quaternary" />
            <input
              className="w-full text-md text-text-primary outline-none placeholder:text-text-placeholder"
              onChange={onSearch}
              placeholder={placeholder}
              ref={inputRef}
              type="text"
              value={value}
            />
          </div>
        ) : (
          <button
            aria-label="Open search"
            className="flex size-full items-center justify-center "
            onClick={() => setIsExpanded(true)}
          >
            <Search className="size-2xl text-fg-quaternary" />
          </button>
        )}
      </div>
    </div>
  );
}
