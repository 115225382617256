import type { UseFormReturn } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

const loginFormSchema = z.object({
  email: z
    .string()
    .min(1, "Please fill in this field.")
    .email("Please enter a valid email address."),
  password: z.string().min(1, "Please fill in this field."),
  rememberMe: z.boolean(),
});

export type LoginFormData = z.infer<typeof loginFormSchema>;

export function useLoginForm(): UseFormReturn<LoginFormData> {
  return useForm<LoginFormData>({
    defaultValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    resolver: zodResolver(loginFormSchema),
  });
}
