import type { UseFormReturn } from "react-hook-form";

import apiClient from "@/lib/api/client";

import type {
  CreateGraphicRequestFormData,
  FileAsset,
  LinkAsset,
} from "../../useCreateGraphicRequestForm";

import { isFileAsset } from "../utils/uploadUtils";

type AssetField = "assets" | "inspirations";

export function useFileDelete(
  field: AssetField,
  assets: (FileAsset | LinkAsset)[],
  setError: (error: null | string) => void,
  methods: UseFormReturn<CreateGraphicRequestFormData>,
): {
  handleFileDelete: (attachmentId: number) => Promise<void>;
} {
  const handleFileDelete = async (attachmentId: number) => {
    setError(null);

    const fileAsset = assets.find(
      (asset): asset is FileAsset =>
        "attachmentId" in asset && asset.attachmentId === attachmentId,
    );

    if (!fileAsset || !fileAsset.id) {
      console.error(`File not found`);

      return;
    }

    try {
      const assetsWithUploadingStatus = assets.map((asset) =>
        isFileAsset(asset) && asset.attachmentId === attachmentId
          ? { ...asset, status: "uploading" as const }
          : asset,
      );

      methods.setValue(field, assetsWithUploadingStatus);

      if (fileAsset.attachmentId) {
        await apiClient
          .delete(`/attachments/${encodeURIComponent(fileAsset.attachmentId)}`)
          .res();
      }

      const currentAssets = methods.getValues(field);
      const filteredAssets = currentAssets.filter(
        (asset) => !isFileAsset(asset) || asset.attachmentId !== attachmentId,
      );

      methods.setValue(field, filteredAssets);
    } catch (error) {
      console.error(`Error deleting file ${attachmentId}:`, error);

      const updatedFilesWithError = assets.map((asset) =>
        isFileAsset(asset) && asset.attachmentId === attachmentId
          ? { ...asset, status: "error" as const }
          : asset,
      );

      methods.setValue(field, updatedFilesWithError);
      setError(
        `Failed to delete file ${fileAsset.file.name}. Please try again.`,
      );
    }
  };

  return { handleFileDelete };
}
