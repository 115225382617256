import BagIcon from "./assets/bag.svg?react";
import BrushIcon from "./assets/brush.svg?react";
import ColorsIcon from "./assets/colors.svg?react";
import EditIcon from "./assets/edit.svg?react";
import FileIcon from "./assets/file.svg?react";
import FilmIcon from "./assets/film.svg?react";
import MailIcon from "./assets/mail.svg?react";
import MessageIcon from "./assets/message.svg?react";
import PackageIcon from "./assets/package.svg?react";
import PresentationIcon from "./assets/presentation.svg?react";
import SaleIcon from "./assets/sale.svg?react";

const PLACEHOLDER_IMAGE_URL = "https://placehold.co/600x400";

export const response = {
  categories: [
    {
      icon: BrushIcon,
      id: 1,
      name: "Graphic",
    },
    {
      icon: FilmIcon,
      id: 2,
      name: "Video",
    },
  ],
  subcategories: [
    {
      categoryId: 1,
      icon: SaleIcon,
      id: 1,
      name: "Digital Ads",
    },
    {
      categoryId: 1,
      icon: MessageIcon,
      id: 2,
      name: "Social Content",
    },
    {
      categoryId: 1,
      icon: BagIcon,
      id: 3,
      name: "Amazon/Ecom",
    },
    {
      categoryId: 1,
      icon: MailIcon,
      id: 4,
      name: "Email Graphics",
    },
    {
      categoryId: 1,
      icon: EditIcon,
      id: 5,
      name: "Vector Illustrations",
    },
    {
      categoryId: 1,
      icon: ColorsIcon,
      id: 6,
      name: "Logo & Branding",
    },
    {
      categoryId: 1,
      icon: PackageIcon,
      id: 7,
      name: "Packaging",
    },
    {
      categoryId: 1,
      icon: FileIcon,
      id: 8,
      name: "Print Media",
    },
    {
      categoryId: 1,
      icon: PresentationIcon,
      id: 9,
      name: "PDF & Presentation",
    },
  ],
  types: [
    {
      categoryId: 1,
      id: 1,
      name: "Meta Ads",
      placeholderImageUrl: PLACEHOLDER_IMAGE_URL,
      subcategoryId: 1,
    },
    {
      categoryId: 1,
      id: 2,
      name: "TikTok Ads",
      placeholderImageUrl: PLACEHOLDER_IMAGE_URL,
      subcategoryId: 1,
    },
    {
      categoryId: 1,
      id: 3,
      name: "Google Display Ads",
      placeholderImageUrl: PLACEHOLDER_IMAGE_URL,
      subcategoryId: 1,
    },
    {
      categoryId: 1,
      id: 4,
      name: "Instagram Ads",
      placeholderImageUrl: PLACEHOLDER_IMAGE_URL,
      subcategoryId: 1,
    },
    {
      categoryId: 1,
      id: 5,
      name: "Pinterest Ads",
      placeholderImageUrl: PLACEHOLDER_IMAGE_URL,
      subcategoryId: 1,
    },
    {
      categoryId: 1,
      id: 6,
      name: "X Ads",
      placeholderImageUrl: PLACEHOLDER_IMAGE_URL,
      subcategoryId: 1,
    },
    {
      categoryId: 1,
      id: 7,
      name: "LinkedIn Ads",
      placeholderImageUrl: PLACEHOLDER_IMAGE_URL,
      subcategoryId: 1,
    },
    {
      categoryId: 1,
      id: 8,
      name: "Feed Graphics",
      placeholderImageUrl: PLACEHOLDER_IMAGE_URL,
      subcategoryId: 2,
    },
    {
      categoryId: 1,
      id: 9,
      name: "Story Graphics",
      placeholderImageUrl: PLACEHOLDER_IMAGE_URL,
      subcategoryId: 2,
    },
    {
      categoryId: 1,
      id: 10,
      name: "Cover Images",
      placeholderImageUrl: PLACEHOLDER_IMAGE_URL,
      subcategoryId: 2,
    },
    {
      categoryId: 1,
      id: 11,
      name: "Podcast Cover Art",
      placeholderImageUrl: PLACEHOLDER_IMAGE_URL,
      subcategoryId: 2,
    },
    {
      categoryId: 1,
      id: 12,
      name: "Meta Banners",
      placeholderImageUrl: PLACEHOLDER_IMAGE_URL,
      subcategoryId: 2,
    },
    {
      categoryId: 1,
      id: 13,
      name: "Background Removal",
      placeholderImageUrl: PLACEHOLDER_IMAGE_URL,
      subcategoryId: 3,
    },
  ],
} as const;
