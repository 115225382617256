import type { Key } from "react-aria-components";

import { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-aria-components";
import { twMerge } from "tailwind-merge";

import LayersIcon from "../assets/layers.svg?react";
import { response } from "../mock";
import { DesignTypesCarousel } from "./DesignTypesCarousel";
import { SearchButton } from "./SearchButton";

type DesignTypeCardProps = {
  name: string;
};

function DesignTypeCard({ name }: DesignTypeCardProps) {
  return (
    <li className="relative h-[214px] w-[222px] shrink-0 overflow-hidden rounded-sm border border-border-secondary bg-bg-primary">
      <div className="size-full bg-utility-gray-200" />
      <div className="absolute bottom-0 left-0 flex w-full items-center justify-center bg-bg-primary py-sm text-md font-medium text-text-secondary">
        {name}
      </div>
    </li>
  );
}

type SubcategoryPanelProps = {
  subcategory: {
    categoryId: number;
    icon?: React.ComponentType;
    id: number;
    name: string;
  };
};

function SubcategoryPanel({ subcategory }: SubcategoryPanelProps) {
  return (
    <TabPanel
      className="flex h-[calc(100vh-300px)] flex-col gap-lg overflow-y-auto px-3xl pt-xs"
      id={subcategory.id.toString()}
    >
      <div className="flex flex-col gap-lg">
        <div className="flex h-5xl items-center">
          <span className="text-lg font-semibold text-text-primary">
            {subcategory.name}
          </span>
        </div>
        <ul className="flex flex-wrap gap-[10px] overflow-x-auto">
          {response.types
            .filter((type) => type.subcategoryId === subcategory.id)
            .map((type) => (
              <DesignTypeCard key={type.id} name={type.name} />
            ))}
        </ul>
      </div>
    </TabPanel>
  );
}

type SubcategoryContentProps = {
  categoryId: number;
  searchQuery: string;
};

function SubcategoryContent({
  categoryId,
  searchQuery,
}: SubcategoryContentProps) {
  const filteredSubcategories = response.subcategories.filter(
    (sub) => sub.categoryId === categoryId,
  );

  const filteredTypes = response.types.filter((type) =>
    type.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <>
      <TabPanel
        className="flex h-[calc(100vh-300px)] flex-col gap-lg overflow-y-auto pt-xs"
        id="0"
      >
        {filteredSubcategories.map((subcategory) => {
          const types = filteredTypes.filter(
            (type) => type.subcategoryId === subcategory.id,
          );

          if (types.length === 0) return null;

          return (
            <DesignTypesCarousel
              designTypes={types}
              key={subcategory.id}
              subcategoryName={subcategory.name}
            />
          );
        })}
      </TabPanel>
      {filteredSubcategories.map((sub) => (
        <SubcategoryPanel key={sub.id} subcategory={sub} />
      ))}
    </>
  );
}

type SubcategoryTabsProps = {
  categoryId: number;
  onSubcategoryChange: (key: Key) => void;
  subcategory: Key;
};

export function SubcategoryTabs({
  categoryId,
  onSubcategoryChange,
  subcategory,
}: SubcategoryTabsProps) {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSearchQuery(value);
    if (value) {
      onSubcategoryChange("0");
    }
  };

  return (
    <Tabs onSelectionChange={onSubcategoryChange} selectedKey={subcategory}>
      <div className="flex items-center justify-between">
        <TabList className="flex max-w-[960px] flex-wrap gap-sm px-3xl pt-lg">
          <Tab
            className={({ isSelected }) =>
              twMerge(
                "flex gap-xs rounded-md border px-lg py-md text-sm font-semibold outline-none cursor-pointer hover:bg-button-secondary-border-hover/15",
                isSelected
                  ? "border-button-secondary-color-border text-button-secondary-color-fg hover:bg-button-secondary-color-bg-hover"
                  : "border-button-secondary-border bg-button-secondary-bg text-button-secondary-fg",
              )
            }
            id="0"
          >
            <LayersIcon />
            All
          </Tab>
          {response.subcategories
            .filter((subcategory) => subcategory.categoryId === categoryId)
            .map((subcategory) => (
              <Tab
                className={({ isSelected }) =>
                  twMerge(
                    "flex gap-xs rounded-md border px-lg py-md text-sm font-semibold outline-none cursor-pointer hover:bg-button-secondary-border-hover/15",
                    isSelected
                      ? "border-button-secondary-color-border text-button-secondary-color-fg hover:bg-button-secondary-color-bg-hover"
                      : "border-button-secondary-border bg-button-secondary-bg text-button-secondary-fg",
                  )
                }
                id={subcategory.id.toString()}
                key={subcategory.id}
              >
                {subcategory?.icon && <subcategory.icon className="size-2xl" />}
                {subcategory.name}
              </Tab>
            ))}
        </TabList>
        <div className="flex self-start px-3xl pt-lg">
          <SearchButton onSearch={handleSearchChange} value={searchQuery} />
        </div>
      </div>
      <SubcategoryContent categoryId={categoryId} searchQuery={searchQuery} />
    </Tabs>
  );
}
