export const brandsKeys = {
  all: ["brands"] as const,
  brandsByProjectId: (projectId?: number) =>
    [...brandsKeys.all, projectId] as const,
};

export const preferredDesignersKeys = {
  all: ["preferred-designers"] as const,
  preferredDesignersByProjectId: (projectId?: number) =>
    [...preferredDesignersKeys.all, projectId] as const,
};

export const collaboratorsKeys = {
  all: ["collaborators"] as const,
  collaboratorsByProjectId: (projectId?: number) =>
    [...collaboratorsKeys.all, projectId] as const,
};

export const legacyDesignTypesKeys = {
  all: ["legacy-design-types"] as const,
  legacyDesignTypesByProjectId: (projectId?: number) =>
    [...legacyDesignTypesKeys.all, projectId] as const,
};
