import { Tab, TabList } from "react-aria-components";
import { twMerge } from "tailwind-merge";

import { Button } from "@/components/button";

import DownloadIcon from "../assets/download.svg?react";
import { response } from "../mock";

export function Header() {
  return (
    <div className="flex items-center justify-between border-b border-border-primary px-3xl py-xl">
      <span className="text-xl font-semibold text-text-primary">
        Create new request
      </span>

      <TabList className="flex divide-x divide-border-primary overflow-hidden rounded-md border border-border-primary">
        {response.categories.map((category) => (
          <Tab
            className={({ isSelected }) =>
              twMerge(
                "cursor-pointer px-xl py-md text-sm font-semibold text-text-secondary outline-none gap-md flex items-center hover:bg-button-secondary-border-hover/15",
                isSelected &&
                  "bg-button-primary-bg text-text-white hover:bg-button-primary-bg-hover",
              )
            }
            id={category.id.toString()}
            key={category.id}
          >
            {category.icon && <category.icon />}
            {category.name}
          </Tab>
        ))}
      </TabList>

      <Button color="gray" variant="tertiary">
        <DownloadIcon />
        Duplicate Past Request
      </Button>
    </div>
  );
}
