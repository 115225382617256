import useEmblaCarousel from "embla-carousel-react";

import ArrowIcon from "../assets/arrow.svg?react";
import { usePrevNextButtons } from "../hooks/usePrevNextButtons";

type DesignTypesCarouselProps = {
  designTypes: {
    categoryId: number;
    id: number;
    name: string;
    placeholderImageUrl: string;
    subcategoryId: number;
  }[];
  subcategoryName: string;
};

export function DesignTypesCarousel({
  designTypes,
  subcategoryName,
}: DesignTypesCarouselProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel();

  const {
    nextBtnDisabled,
    onNextButtonClick,
    onPrevButtonClick,
    prevBtnDisabled,
  } = usePrevNextButtons(emblaApi);

  return (
    <div className="flex flex-col gap-lg">
      <div className="flex h-5xl items-center justify-between px-3xl">
        <span className="text-lg font-semibold text-text-primary">
          {subcategoryName}
        </span>
        <div className="flex divide-x divide-border-primary overflow-hidden rounded-md border border-border-primary">
          <button
            className="flex cursor-pointer items-center px-lg py-md text-sm font-semibold text-text-secondary outline-none hover:bg-button-secondary-border-hover/15 disabled:cursor-default disabled:text-fg-disabled disabled:hover:bg-bg-primary"
            disabled={prevBtnDisabled}
            onClick={onPrevButtonClick}
          >
            <ArrowIcon className="rotate-180" />
          </button>
          <button
            className="flex cursor-pointer items-center px-lg py-md text-sm font-semibold text-text-secondary outline-none hover:bg-button-secondary-border-hover/15 disabled:cursor-default disabled:text-fg-disabled disabled:hover:bg-bg-primary"
            disabled={nextBtnDisabled}
            onClick={onNextButtonClick}
          >
            <ArrowIcon />
          </button>
        </div>
      </div>
      <div className="max-w-min overflow-hidden" ref={emblaRef}>
        <div className="flex gap-[10px] pl-3xl">
          {designTypes.map((type) => (
            <div
              className="relative h-[214px] w-[233px] shrink-0 overflow-hidden rounded-sm border border-border-secondary bg-bg-primary"
              key={type.id}
            >
              <div className="size-full bg-utility-gray-200 " />
              <div className="absolute bottom-0 left-0 flex w-full items-center justify-center bg-bg-primary py-sm text-md font-medium text-text-secondary">
                {type.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
