import { useIsMutating } from "@tanstack/react-query";
import { Controller } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { Button } from "@/components/button";
import { FieldError, Label, TextArea, TextField } from "@/components/field";
import { Tooltip, TooltipTrigger } from "@/components/tooltip";

import HelpIcon from "../../../shared/assets/help.svg?react";
import { useCreateVideoRequestFormContext } from "../useCreateVideoRequestForm";

interface DetailedFormProps {
  isDisabled: boolean;
}

export function DetailedForm({ isDisabled }: DetailedFormProps) {
  const methods = useCreateVideoRequestFormContext();

  const isPending = Boolean(
    useIsMutating({
      mutationKey: ["createOutputEnhancement"], // TODO: find scalable solution for handling query keys
      status: "pending",
    }),
  );

  const isBlocked = isDisabled || isPending;

  return (
    <div className="col-span-1 row-span-1 flex flex-col gap-3xl">
      <Controller
        control={methods.control}
        name="briefCreatorDetailed.scope"
        render={({
          field: { name, onBlur, onChange, ref, value },
          fieldState: { error, invalid },
        }) => (
          <TextField
            isDisabled={isBlocked}
            isInvalid={invalid}
            isRequired
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            validationBehavior="aria"
            value={value}
          >
            <div className="flex items-center justify-between">
              <Label requiredHint>🎯 Scope</Label>
              <TooltipTrigger delay={0}>
                <Button
                  aria-label="Scope help"
                  className="h-min cursor-default"
                  variant="unstyled"
                >
                  <HelpIcon className="text-fg-quinary" />
                </Button>
                <Tooltip offset={0} placement="right">
                  Outlines the project&apos;s primary objectives and overall
                  aims, setting the foundation for expected business or
                  marketing outcomes.
                </Tooltip>
              </TooltipTrigger>
            </div>
            <TextArea
              className="resize-none"
              placeholder="e.g. Create a 30-second promotional video for eco-friendly skincare products to boost brand awareness and increase online sales."
              ref={ref}
              rows={5}
            />
            <div className="mt-0 flex items-start justify-between">
              <div className="-mt-sm flex-1">
                <FieldError>{error?.message}</FieldError>
              </div>
              <span
                className={twMerge(
                  "ml-4 shrink-0 text-sm text-text-tertiary",
                  error && "text-text-error-primary",
                )}
              >{`${value?.length || 0}/800`}</span>
            </div>
          </TextField>
        )}
      />

      <Controller
        control={methods.control}
        name="briefCreatorDetailed.creativeGoal"
        render={({
          field: { name, onBlur, onChange, ref, value },
          fieldState: { error, invalid },
        }) => (
          <TextField
            isDisabled={isBlocked}
            isInvalid={invalid}
            isRequired
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            validationBehavior="aria"
            value={value}
          >
            <div className="flex items-center justify-between">
              <Label requiredHint>🌈 Creative Goal</Label>
              <TooltipTrigger delay={0}>
                <Button
                  aria-label="Creative goal help"
                  className="h-min cursor-default"
                  variant="unstyled"
                >
                  <HelpIcon className="text-fg-quinary" />
                </Button>
                <Tooltip offset={0} placement="right">
                  Specifies the project’s artistic and stylistic approach,
                  including visual intent, brand guidelines adherence, and
                  creative inspirations.
                </Tooltip>
              </TooltipTrigger>
            </div>
            <TextArea
              className="resize-none"
              placeholder="e.g. Showcase the product's natural ingredients and demonstrate its effectiveness through before-and-after shots."
              ref={ref}
              rows={5}
            />
            <div className="mt-0 flex items-start justify-between">
              <div className="-mt-sm flex-1">
                <FieldError>{error?.message}</FieldError>
              </div>
              <span
                className={twMerge(
                  "ml-4 shrink-0 text-sm text-text-tertiary",
                  error && "text-text-error-primary",
                )}
              >{`${value?.length || 0}/800`}</span>
            </div>
          </TextField>
        )}
      />

      <Controller
        control={methods.control}
        name="briefCreatorDetailed.script"
        render={({
          field: { name, onBlur, onChange, ref, value },
          fieldState: { error, invalid },
        }) => (
          <TextField
            isDisabled={isBlocked}
            isInvalid={invalid}
            isRequired
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            validationBehavior="aria"
            value={value}
          >
            <div className="flex items-center justify-between">
              <Label requiredHint>💬 Script</Label>
              <TooltipTrigger delay={0}>
                <Button
                  aria-label="Script help"
                  className="h-min cursor-default"
                  variant="unstyled"
                >
                  <HelpIcon className="text-fg-quinary" />
                </Button>
                <Tooltip offset={0} placement="right">
                  Describes the video content scene-by-scene, including visuals,
                  actions, and key messages. Outlines the narrative flow and
                  specifies important elements to be featured in each segment of
                  the video.
                </Tooltip>
              </TooltipTrigger>
            </div>
            <TextArea
              className="resize-none"
              placeholder={`Scene 1: Close-up of product in natural setting.
Scene 2: Demonstration of product application. 
Scene 3: Time-lapse showing visible skin improvement.
Scene 4: Highlight key organic ingredients. 
Scene 5: Call-to-action with brand logo.`}
              ref={ref}
              rows={5}
            />
            <div className="mt-0 flex items-start justify-between">
              <div className="-mt-sm flex-1">
                <FieldError>{error?.message}</FieldError>
              </div>
              <span
                className={twMerge(
                  "ml-4 shrink-0 text-sm text-text-tertiary",
                  error && "text-text-error-primary",
                )}
              >{`${value?.length || 0}/800`}</span>
            </div>
          </TextField>
        )}
      />

      <Controller
        control={methods.control}
        name="briefCreatorDetailed.brandGuidelines"
        render={({
          field: { name, onBlur, onChange, ref, value },
          fieldState: { error, invalid },
        }) => (
          <TextField
            isDisabled={isBlocked}
            isInvalid={invalid}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            validationBehavior="aria"
            value={value}
          >
            <div className="flex items-center justify-between">
              <Label>📘 Brand Guidelines</Label>
              <TooltipTrigger delay={0}>
                <Button
                  aria-label="Brand guidelines help"
                  className="h-min cursor-default"
                  variant="unstyled"
                >
                  <HelpIcon className="text-fg-quinary" />
                </Button>
                <Tooltip offset={0} placement="right">
                  Specify which elements of your selected brand to use in this
                  design. Indicate any preferences for fonts, colors, logos, or
                  other brand elements, and mention any exclusions if certain
                  aspects should not be incorporated.
                </Tooltip>
              </TooltipTrigger>
            </div>
            <TextArea
              className="resize-none"
              placeholder="e.g. Utilize green and beige colors; logo in the upper right corner."
              ref={ref}
              rows={3}
            />
            <div className="mt-0 flex items-start justify-between">
              <div className="-mt-sm flex-1">
                <FieldError>{error?.message}</FieldError>
              </div>
              <span
                className={twMerge(
                  "ml-4 shrink-0 text-sm text-text-tertiary",
                  error && "text-text-error-primary",
                )}
              >{`${value?.length || 0}/800`}</span>
            </div>
          </TextField>
        )}
      />

      <Controller
        control={methods.control}
        name="briefCreatorDetailed.additionalGuidelines"
        render={({
          field: { name, onBlur, onChange, ref, value },
          fieldState: { error, invalid },
        }) => (
          <TextField
            isDisabled={isBlocked}
            isInvalid={invalid}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            validationBehavior="aria"
            value={value}
          >
            <div className="flex items-center justify-between">
              <Label>✏️ Additional Guidelines</Label>
              <TooltipTrigger delay={0}>
                <Button
                  aria-label="Additional guidelines help"
                  className="h-min cursor-default"
                  variant="unstyled"
                >
                  <HelpIcon className="text-fg-quinary" />
                </Button>
                <Tooltip offset={0} placement="right">
                  Provides further instructions or specific details not covered
                  elsewhere, such as legal disclaimers, language use, and design
                  constraints
                </Tooltip>
              </TooltipTrigger>
            </div>
            <TextArea
              className="resize-none"
              placeholder="e.g. Incorporate soft background music."
              ref={ref}
              rows={3}
            />
            <div className="mt-0 flex items-start justify-between">
              <div className="-mt-sm flex-1">
                <FieldError>{error?.message}</FieldError>
              </div>
              <span
                className={twMerge(
                  "ml-4 shrink-0 text-sm text-text-tertiary",
                  error && "text-text-error-primary",
                )}
              >{`${value?.length || 0}/800`}</span>
            </div>
          </TextField>
        )}
      />
    </div>
  );
}
