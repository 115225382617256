import type { LoginBodyZod, LoginResponseZod } from "@nlc/shared-types";
import type { z } from "zod";

import { useMutation } from "@tanstack/react-query";

import { unauthenticatedApi } from "@/lib/api/client";

export function useLoginMutation() {
  return useMutation({
    mutationFn: async ({
      email,
      password,
      rememberMe,
    }: z.infer<typeof LoginBodyZod>) =>
      await unauthenticatedApi
        .url("/auth/login")
        .post({ email, password, rememberMe })
        .json<z.infer<typeof LoginResponseZod>>(),
  });
}
