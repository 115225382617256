import type { Key } from "react-aria-components";

import { useState } from "react";
import { TabPanel, Tabs } from "react-aria-components";

import { Header } from "./components/Header";
import { SubcategoryTabs } from "./components/SubcategoryTabs";
import { response } from "./mock";

export function PickType() {
  const [category, setCategory] = useState<Key>(1);
  const [subcategory, setSubcategory] = useState<Key>(0);

  return (
    <div className="h-[calc(100vh-70px)] w-[calc(100vw-220px)] overflow-hidden bg-bg-tertiary p-lg">
      <div className="mx-auto h-full min-w-[1200px] max-w-[1500px] overflow-hidden rounded-xl border border-border-secondary bg-bg-primary">
        <Tabs onSelectionChange={setCategory} selectedKey={category}>
          <Header />
          {response.categories.map((cat) => (
            <TabPanel
              className="overflow-hidden"
              id={cat.id.toString()}
              key={cat.id}
            >
              <SubcategoryTabs
                categoryId={cat.id}
                onSubcategoryChange={setSubcategory}
                subcategory={subcategory}
              />
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </div>
  );
}
