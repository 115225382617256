import { Button } from "@/components/button";

import ChevronLeftIcon from "../assets/chevron-left.svg?react";

export function Header({ requestName }: { requestName?: string }) {
  return (
    <div className="flex items-center justify-start gap-2xl border-b border-border-secondary px-3xl pb-2xl pt-xl">
      <Button
        color="gray"
        isIconOnly
        onPress={() => {
          window.location.href = "/requests?active=1";
        }}
        size="lg"
        variant="secondary"
      >
        <ChevronLeftIcon aria-label="Back" />
      </Button>
      <span className="line-clamp-1 text-display-xs font-semibold text-text-primary">
        {requestName || "Request Name"}
      </span>
    </div>
  );
}
