import type { DefaultError } from "@tanstack/react-query";

import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useListData } from "react-stately";

import { Description, Label } from "@/components/field";
import {
  MultiSelect,
  MultiSelectField,
  MultiSelectItem,
} from "@/components/multi-select";
import { Select, SelectButton } from "@/components/select";
import { Tag } from "@/components/tag-group";
import { collaboratorsKeys } from "@/hooks/queryKeys";
import { useGetCurrentProjectId } from "@/hooks/useGetCurrentProjectId";
import apiClient from "@/lib/api/client";

import { useCreateGraphicRequestFormContext } from "../useCreateGraphicRequestForm";

// TODO: replace with shared types when ready
type Collaborator = {
  id: string;
  textValue: string;
};

type Response = {
  collaborators: {
    id: string;
    name: string;
  }[];
};

type MappedResponse = Collaborator[];

function useGetCollaborators(projectId?: number) {
  return useQuery<Response, DefaultError, MappedResponse>({
    enabled: !!projectId,
    queryFn: async () =>
      await apiClient.url(`/projects/${projectId}/collaborators`).get().json(),
    queryKey: collaboratorsKeys.collaboratorsByProjectId(projectId),
    select: (data) =>
      data.collaborators.map(({ id, name }) => ({ id, textValue: name })),
  });
}

export function CollaboratorsSelect() {
  const methods = useCreateGraphicRequestFormContext();
  const projectId = useGetCurrentProjectId();
  const { data } = useGetCollaborators(projectId);

  const defaultItems = methods.getValues("collaborators");

  const selectedList = useListData<Collaborator>({
    getKey: (item) => item.id,
    initialItems: [],
  });

  useEffect(() => {
    if (data) {
      selectedList.remove(...selectedList.items.map((item) => item.id));
      selectedList.append(
        ...data.filter((item) => defaultItems.includes(item.id)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, defaultItems]);

  if (data) {
    return (
      <Controller
        control={methods.control}
        name="collaborators"
        render={({ field: { name, onBlur, onChange, value } }) => (
          <MultiSelectField className="col-span-6">
            <Label>Collaborators</Label>
            <MultiSelect
              items={data}
              name={name}
              onBlur={onBlur}
              onItemAdd={(key) => {
                const newValue = [...(value || []), key.toString()];

                onChange(newValue);
              }}
              onItemRemove={(key) => {
                const newValue = (value || []).filter(
                  (id) => id !== key.toString(),
                );

                onChange(newValue);
              }}
              placeholder="Select"
              renderEmptyState={(inputValue) => (
                <span className="p-2">
                  {inputValue ? (
                    <>No results found for: {inputValue}</>
                  ) : (
                    `No collaborators available`
                  )}
                </span>
              )}
              selectedList={selectedList}
              tag={(item) => <Tag textValue={item.id}>{item.textValue}</Tag>}
            >
              {(item) => (
                <MultiSelectItem textValue={item.id}>
                  {item.textValue}
                </MultiSelectItem>
              )}
            </MultiSelect>
            <Description>
              Adding a collaborator will give them access to view project
              details, uploaded project files and comments between our team and
              yours. Please make sure you are only adding collaborators who you
              want to access this project fully.
            </Description>
          </MultiSelectField>
        )}
      />
    );
  }

  return (
    <Select className="col-span-6" placeholder="Loading...">
      <Label>Collaborators</Label>
      <SelectButton />
      <Description>
        Adding a collaborator will give them access to view project details,
        uploaded project files and comments between our team and yours. Please
        make sure you are only adding collaborators who you want to access this
        project fully.
      </Description>
    </Select>
  );
}
