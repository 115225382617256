/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LoginImport } from './routes/login'
import { Route as FeatureImport } from './routes/feature'
import { Route as IndexImport } from './routes/index'
import { Route as RequestsLayoutImport } from './routes/requests/_layout'
import { Route as RequestsLayoutPickTypeImport } from './routes/requests/_layout.pick-type'
import { Route as RequestsLayoutCreateImport } from './routes/requests/_layout.create'

// Create Virtual Routes

const RequestsImport = createFileRoute('/requests')()

// Create/Update Routes

const RequestsRoute = RequestsImport.update({
  id: '/requests',
  path: '/requests',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const FeatureRoute = FeatureImport.update({
  id: '/feature',
  path: '/feature',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const RequestsLayoutRoute = RequestsLayoutImport.update({
  id: '/_layout',
  getParentRoute: () => RequestsRoute,
} as any)

const RequestsLayoutPickTypeRoute = RequestsLayoutPickTypeImport.update({
  id: '/pick-type',
  path: '/pick-type',
  getParentRoute: () => RequestsLayoutRoute,
} as any)

const RequestsLayoutCreateRoute = RequestsLayoutCreateImport.update({
  id: '/create',
  path: '/create',
  getParentRoute: () => RequestsLayoutRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/feature': {
      id: '/feature'
      path: '/feature'
      fullPath: '/feature'
      preLoaderRoute: typeof FeatureImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/requests': {
      id: '/requests'
      path: '/requests'
      fullPath: '/requests'
      preLoaderRoute: typeof RequestsImport
      parentRoute: typeof rootRoute
    }
    '/requests/_layout': {
      id: '/requests/_layout'
      path: '/requests'
      fullPath: '/requests'
      preLoaderRoute: typeof RequestsLayoutImport
      parentRoute: typeof RequestsRoute
    }
    '/requests/_layout/create': {
      id: '/requests/_layout/create'
      path: '/create'
      fullPath: '/requests/create'
      preLoaderRoute: typeof RequestsLayoutCreateImport
      parentRoute: typeof RequestsLayoutImport
    }
    '/requests/_layout/pick-type': {
      id: '/requests/_layout/pick-type'
      path: '/pick-type'
      fullPath: '/requests/pick-type'
      preLoaderRoute: typeof RequestsLayoutPickTypeImport
      parentRoute: typeof RequestsLayoutImport
    }
  }
}

// Create and export the route tree

interface RequestsLayoutRouteChildren {
  RequestsLayoutCreateRoute: typeof RequestsLayoutCreateRoute
  RequestsLayoutPickTypeRoute: typeof RequestsLayoutPickTypeRoute
}

const RequestsLayoutRouteChildren: RequestsLayoutRouteChildren = {
  RequestsLayoutCreateRoute: RequestsLayoutCreateRoute,
  RequestsLayoutPickTypeRoute: RequestsLayoutPickTypeRoute,
}

const RequestsLayoutRouteWithChildren = RequestsLayoutRoute._addFileChildren(
  RequestsLayoutRouteChildren,
)

interface RequestsRouteChildren {
  RequestsLayoutRoute: typeof RequestsLayoutRouteWithChildren
}

const RequestsRouteChildren: RequestsRouteChildren = {
  RequestsLayoutRoute: RequestsLayoutRouteWithChildren,
}

const RequestsRouteWithChildren = RequestsRoute._addFileChildren(
  RequestsRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/feature': typeof FeatureRoute
  '/login': typeof LoginRoute
  '/requests': typeof RequestsLayoutRouteWithChildren
  '/requests/create': typeof RequestsLayoutCreateRoute
  '/requests/pick-type': typeof RequestsLayoutPickTypeRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/feature': typeof FeatureRoute
  '/login': typeof LoginRoute
  '/requests': typeof RequestsLayoutRouteWithChildren
  '/requests/create': typeof RequestsLayoutCreateRoute
  '/requests/pick-type': typeof RequestsLayoutPickTypeRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/feature': typeof FeatureRoute
  '/login': typeof LoginRoute
  '/requests': typeof RequestsRouteWithChildren
  '/requests/_layout': typeof RequestsLayoutRouteWithChildren
  '/requests/_layout/create': typeof RequestsLayoutCreateRoute
  '/requests/_layout/pick-type': typeof RequestsLayoutPickTypeRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/feature'
    | '/login'
    | '/requests'
    | '/requests/create'
    | '/requests/pick-type'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/feature'
    | '/login'
    | '/requests'
    | '/requests/create'
    | '/requests/pick-type'
  id:
    | '__root__'
    | '/'
    | '/feature'
    | '/login'
    | '/requests'
    | '/requests/_layout'
    | '/requests/_layout/create'
    | '/requests/_layout/pick-type'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  FeatureRoute: typeof FeatureRoute
  LoginRoute: typeof LoginRoute
  RequestsRoute: typeof RequestsRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  FeatureRoute: FeatureRoute,
  LoginRoute: LoginRoute,
  RequestsRoute: RequestsRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/feature",
        "/login",
        "/requests"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/feature": {
      "filePath": "feature.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/requests": {
      "filePath": "requests",
      "children": [
        "/requests/_layout"
      ]
    },
    "/requests/_layout": {
      "filePath": "requests/_layout.tsx",
      "parent": "/requests",
      "children": [
        "/requests/_layout/create",
        "/requests/_layout/pick-type"
      ]
    },
    "/requests/_layout/create": {
      "filePath": "requests/_layout.create.tsx",
      "parent": "/requests/_layout"
    },
    "/requests/_layout/pick-type": {
      "filePath": "requests/_layout.pick-type.tsx",
      "parent": "/requests/_layout"
    }
  }
}
ROUTE_MANIFEST_END */
