import type { DefaultError } from "@tanstack/react-query";

import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useListData } from "react-stately";

import { Label } from "@/components/field";
import {
  MultiSelect,
  MultiSelectField,
  MultiSelectItem,
} from "@/components/multi-select";
import { Select, SelectButton } from "@/components/select";
import { Tag } from "@/components/tag-group";
import { useCapturePosthogEvent } from "@/hooks/posthog/useCapturePosthogEvent";
import { preferredDesignersKeys } from "@/hooks/queryKeys";
import { useGetCurrentProjectId } from "@/hooks/useGetCurrentProjectId";
import apiClient from "@/lib/api/client";

import { useCreateGraphicRequestFormContext } from "../useCreateGraphicRequestForm";

// TODO: replace with shared types when ready
type PreferredDesigner = {
  id: string;
  textValue: string;
};

type Response = {
  preferredDesigners: {
    id: string;
    name: string;
  }[];
};

type MappedResponse = PreferredDesigner[];

function useGetPreferredDesigners(projectId?: number) {
  return useQuery<Response, DefaultError, MappedResponse>({
    enabled: !!projectId,
    queryFn: async () =>
      await apiClient
        .url(`/projects/${projectId}/preferred-designers`)
        .get()
        .json(),
    queryKey: preferredDesignersKeys.preferredDesignersByProjectId(projectId),
    select: (data) =>
      data.preferredDesigners.map(({ id, name }) => ({ id, textValue: name })),
  });
}

export function PreferredDesignersSelect({
  className,
}: {
  className?: string;
}) {
  const capture = useCapturePosthogEvent();

  const methods = useCreateGraphicRequestFormContext();
  const projectId = useGetCurrentProjectId();
  const { data } = useGetPreferredDesigners(projectId);

  const defaultItems = methods.getValues("preferredDesigners");

  const selectedList = useListData<PreferredDesigner>({
    getKey: (item) => item.id,
    initialItems: [],
  });

  useEffect(() => {
    if (data) {
      selectedList.remove(...selectedList.items.map((item) => item.id));
      selectedList.append(
        ...data.filter((item) => defaultItems.includes(item.id)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, defaultItems]);

  if (data) {
    return (
      <Controller
        control={methods.control}
        name="preferredDesigners"
        render={({ field: { name, onBlur, onChange, value } }) => (
          <MultiSelectField className={className}>
            <Label>Preferred designers</Label>
            <MultiSelect
              items={data}
              name={name}
              onBlur={onBlur}
              onItemAdd={(key) => {
                const newValue = [...(value || []), key.toString()];

                capture("designer_preference_set");

                onChange(newValue);
              }}
              onItemRemove={(key) => {
                const newValue = (value || []).filter(
                  (id) => id !== key.toString(),
                );

                onChange(newValue);
              }}
              placeholder="Select"
              renderEmptyState={(inputValue) => (
                <span className="p-2">
                  {inputValue ? (
                    <>No results found for: {inputValue}</>
                  ) : (
                    `No designers available`
                  )}
                </span>
              )}
              selectedList={selectedList}
              tag={(item) => <Tag textValue={item.id}>{item.textValue}</Tag>}
            >
              {(item) => (
                <MultiSelectItem textValue={item.id}>
                  {item.textValue}
                </MultiSelectItem>
              )}
            </MultiSelect>
          </MultiSelectField>
        )}
      />
    );
  }

  return (
    <Select className={className} placeholder="Loading...">
      <Label>Preferred designers</Label>
      <SelectButton />
    </Select>
  );
}
